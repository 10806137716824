<template>
	<input 
        :list="inputName" 
        class="form-control" 
        autocomplete="off" 
        v-model="value"
        v-on:keyup="onEntry"
        v-on:change="onSelect" 
        v-on:dblclick="onDblClick"
        placeholder="Pesquisar..." />

    <datalist :id="inputName" >
		<option v-for="item in list" :key="item[fieldKey]" :data-id="item[fieldKey]" >
            {{ item[fieldText] }}
        </option>
    </datalist>
</template> 

<script>

import ApiAxios from '../../services/api-axios';
import { sAlert } from '../utils/jalerts';

export default {
	props: {
        inputName: String,
		collection: String,
        object: String,
		fieldKey: String,
		fieldText: String,
        current: String,
        rows: String,
        filter: Object
	},
    emits: ["select"],
	data() {
		return {
            selected: '',
            value: '',
			list: [],
		}
	},
    created() {
        this.fetchCurrent();
	},
    watch: {
        current(){
            this.fetchCurrent();
        }
    },
	methods: {
		fetchList(param) {
            if (!param || ( param && param.length <= 3 )) return;
            ApiAxios.get(`${this.collection}`, {
				params: {
					fields: `${this.fieldKey},${this.fieldText}`,
					page: 0,
					sort: this.fieldText,
					filter: { ...this.filter, [this.fieldText]: param }
				}
			})
			.then((res) => {
                this.list = res.data[this.collection];
			})
			.catch((e) => {
                sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
			});
		},
        select(sel) {
            if (!sel) return;
            this.$emit('select', sel);
        },
        onSelect(input) {
			let value = input.target.value;
            if (!value) return null;
            let option = Array.prototype.find.call(input.target.list.options, function(option) {
				return option.value === value;
			});
			this.select(option.getAttribute("data-id"));
		},
        onEntry(input) {
			let param = input.target.value;
            this.fetchList(param);
		},
        onDblClick() {
            this.selected = '';
            this.value = '';
            this.$emit('select', null);
        },
        fetchCurrent() {
            if (!this.current) return;
            
            ApiAxios.get(`${this.collection}/${this.current}`)
                .then((res) => {
                    this.list = [res.data[this.object]];
                    let objSelected = res.data[this.object];
                    this.selected = objSelected[this.fieldKey];
                    this.value = objSelected[this.fieldText];
                })
                .catch((e) => {
                    if (!e.response) { return sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e }); }
                    sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
                });
		},
	}
}
</script>