<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Manutenção</li>
    </ol>
  </nav>
  <div class="table-responsive">
    <div class="container my-3 p-3 bg-light rounded box-shadow">
      <h5>Manutenção de Participantes e Atividades</h5>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>Atividades</label>
          <InputSelect collection="activities" object="activity" fieldKey="id" fieldText="name" v-bind:current="filter.activityId" @select="(sel) => filter.activityId = sel" rows="200"/>
        </div>
        <div class="form-group col-md-6">
          <label>Associado</label>
          <InputSearch inputName="activity-associate" collection="associates" object="associate" fieldKey="id" fieldText="name" current='' @select="(sel) => filter.associateId = sel" rows="200" :filter="{inactive: false}"/>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Modalidade</label>
          <InputSelect collection="modalities" object="modality" fieldKey="id" fieldText="name" v-bind:current="filter.modalityId" @select="(sel) => filter.modalityId = sel" rows="200"/>
        </div>
        <div class="form-group col-md-4">
          <label>Local</label>
          <InputSelect collection="locations" object="location" fieldKey="id" fieldText="name" v-bind:current="filter.locationId" @select="(sel) => filter.locationId = sel" rows="200"/>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4">
          <label>Data inicial</label>
          <input type="date" class="form-control" v-model="filter.startDate" >
        </div>
        <div class="form-group col-md-4">
          <label>Data final</label>
          <input type="date" class="form-control" v-model="filter.endDate" >
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-2 col-xs-2">
            <a type="button" @click="search()" class="btn btn-dark">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
              </svg>
              Buscar
            </a>
          </div>
        </div>
      </div>
    </div>

    <p><small>* As colunas P,FJ e F correspodem respectivamente as presenças, faltas justificadas e faltas.</small></p>
    <div class="card mb-1" v-for="record in records" :key="record.id">
      <div class="row card-body">
        <div class="col-9">
          <h5 class="card-title">{{ record.activity }}</h5>
          
          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <p class="card-text">Nome:</p>
              </div>
              <div class="col-md-2">
                <p class="card-text">P / FJ / F:</p>
              </div>
              <div class="col-md-2">
                <p class="card-text">Parcial:</p>
              </div>
              <div class="col-2">
                <p class="card-text">Ações:</p>
              </div>
            </div>
          </div>
          <div class="form-group" v-for="detail in record.details" :key="detail.activity_associate_id">
            <div class="row">
              <div class="col-md-6">
                <p class="card-text">{{ detail.name }}</p>
              </div>
              <div class="col-md-2">
                <p class="card-text">{{ detail.present || 0 }} - {{ detail.justified || 0 }} - {{ detail.absent || 0 }}</p>
              </div>
              <div class="col-md-2">
                <p class="card-text">{{ !detail.partialpresence ? 'Não' : 'Sim' }}</p>
              </div>
              <div class="col-2">
                <button type="button" class="btn btn-dark ml-1" @click="deleteRecord(detail.activity_associate_id)" v-if="!showBtnDelete" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eraser" viewBox="0 0 16 16">
                    <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414l-3.879-3.879zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z"/>
                  </svg>
                </button>
                <!-- <router-link class="btn btn-dark" :to="{ name: 'activityPresenceList', params: { id: detail.activity_associate_id }}" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                  </svg>
                </router-link> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <PieChart :chartData="this.pieChartData(record)"/>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { sAlert, sDialog } from '../utils/jalerts';
import ApiAxios from '../../services/api-axios';
import moment from 'moment';
import InputSelect from '../commons/InputSelect.vue';
import InputSearch from '../commons/InputSearch.vue';
import PieChart from '../chart/PieChart.vue';

export default {
  components: {
    InputSelect,
    InputSearch,
    PieChart
  },
  data() {
    return {
			records: [],
			strFields: '',
			page: 1,
			size: 10,
			pages: 0,
			sort: '',
			param: '',
      filter: {
        modalityId: null,
        activityId: null,
        associateId: null,
        locationId: null,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      }
		};
  },
  methods: {
		callSearch(e) {
			if (e.keyCode === 13) this.search();
		},
		async fetchGetData() {
			this.$root.loadingVisible = true;
			ApiAxios.get(`activities/management`, {
				params: {
					page: 0,
					sort: this.sort,
					size: 100,
					filter: this.filter,
				}
			})
			.then((res) => {
				this.records = res.data.result;
				// this.pages = res.data.totalPages;
				this.$root.loadingVisible = false;
			})
			.catch((e) => {
				this.$root.loadingVisible = false;
				if (e.response.status == 403) {
					this.$router.back();
					return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
				}
				sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
			});
		},
		search() {
			if(this.page === 1) { return this.fetchGetData(); }
			this.page = 1;
		},

    pieChartData(activity) {
      let data = [];
      let { present, justified, absent } = activity;
      let total = parseInt(present || 0) + parseInt(justified || 0) + parseInt(absent || 0);
      data.push(((100/total) * parseInt(present || 0)).toFixed(2));
      data.push(((100/total) * parseInt(justified || 0)).toFixed(2));
      data.push(((100/total) * parseInt(absent || 0)).toFixed(2));

      let chartData = {
        labels: ['Presença', 'Justificada', 'Falta'],
        datasets: [
          {
            backgroundColor: ['#41B883', '#00D8FF', '#DD1B16'],
            data
          }
        ]
      };

      return chartData;
    },

    deleteRecord(id) {
			let that = this;
			sDialog({
				text: 'Confirma a exclusão do associado nessa atividade?',
				confirmFunction() {
					ApiAxios.delete(`activityAssociates/${id}`)
						.then(() => {
							that.fetchGetData();
							sAlert({ type: 'success', text: 'Registro excluído com sucesso.' });
						})
						.catch((e) => {
							if (e.response && e.response.status == 403) {
								return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
							}
							sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
						});  
				},
			})
		},
	},
}
</script>
